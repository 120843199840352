import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormattedTextFieldComponent } from '../../../../components/formatted-text-field/formatted-text-field.component';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ContainerInside } from '../../../../components/structure';
import { DENSITY_ENTITY, VOLUME_ENTITY, WEIGHT_ENTITY } from '../../../../constants';
import {
  MeasurementOption,
  NewArticle,
  TareMode,
  Tolerances,
  TareDeterminationMode,
} from '../../../../model';
import { changeArticleFertigPackInputValues } from '../../../../store';

import { ArticleFormData } from './article-form.component';
import { articleFormClasses } from './article-form.component.styles';

export interface OwnProps {
  resetTolerances: () => void;
  resetCountTolerances: number;
  currentWeightTolerances: Tolerances | undefined;
  currentVolumeTolerances: Tolerances | undefined;
  clearTolerance: () => void;
  setResetCountTolerances: (value: React.SetStateAction<number>) => void;
  setShouldGetTolerances: (value: React.SetStateAction<boolean>) => void;
  article?: NewArticle;
}

export const ArticleFormNominalCapacityComponent = (props: OwnProps) => {
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<ArticleFormData>();
  const { t } = useTranslation(['data']);
  const {
    article,
    resetTolerances,
    resetCountTolerances,
    currentVolumeTolerances,
    currentWeightTolerances,
    clearTolerance,
    setResetCountTolerances,
    setShouldGetTolerances,
  } = props;
  const dispatch = useDispatch();

  const [nominalValueInput, setNominalValueInput] = useState<string | undefined>(
    !article?.volumeValue ? article?.nominalValue?.toString() || '' : ''
  );
  const [nominalValueVolumeInput, setNominalValueVolumeInput] = useState<string | undefined>(
    article?.volumeValue ? article?.nominalValue?.toString() || '' : ''
  );
  const [tareValueInput, setTareValueInput] = useState<string | undefined>(
    article?.tareValue?.toString() || ''
  );
  const [tareModeSwitched, setTareModeSwitched] = useState<boolean>(false);
  const [volumeValueInput, setVolumeValueInput] = useState<string | undefined>(
    article?.volumeValue?.toString() || ''
  );
  const [densityValueInput, setDensityValueInput] = useState<string | undefined>(
    article?.densityValue?.toString() || ''
  );
  const [lowerToleranceInput, setLowerToleranceInput] = useState<string | undefined>(
    !article?.volumeValue ? article?.lowerTolerance?.toString() || '' : ''
  );
  const [lowerToleranceVolumeInput, setLowerToleranceVolumeInput] = useState<string | undefined>(
    article?.volumeValue ? article?.lowerTolerance?.toString() || '' : ''
  );
  const [upperToleranceInput, setUpperToleranceInput] = useState<string | undefined>(
    !article?.volumeValue ? article?.upperTolerance?.toString() || '' : ''
  );
  const [upperToleranceVolumeInput, setUpperToleranceVolumeInput] = useState<string | undefined>(
    article?.volumeValue ? article?.upperTolerance?.toString() || '' : ''
  );
  const [singleTareMaxValueInput, setSingleTareMaxValueInput] = useState<string | undefined>(
    article?.tareMode === TareMode.Single && article?.tareMaxValue
      ? article?.tareMaxValue?.toString() || ''
      : ''
  );
  const [singleTareMinValueInput, setSingleTareMinValueInput] = useState<string | undefined>(
    article?.tareMode === TareMode.Single && article?.tareMinValue
      ? article?.tareMinValue?.toString() || ''
      : ''
  );
  const [individualTareMaxValueInput, setIndividualTareMaxValueInput] = useState<
    string | undefined
  >(
    article?.tareMode === TareMode.OrderedIndividual && article?.tareMaxValue
      ? article?.tareMaxValue?.toString() || ''
      : ''
  );
  const [individualTareMinValueInput, setIndividualTareMinValueInput] = useState<
    string | undefined
  >(
    article?.tareMode === TareMode.OrderedIndividual && article?.tareMinValue
      ? article?.tareMinValue?.toString() || ''
      : ''
  );

  const articleTareMode = article?.tareMode;
  const [measurementOptionChanged, setMeasurementOptionChanged] = useState<boolean>(false);
  const [newNominalDefaultValue, setNewNominalDefaultValue] = useState<number | string>('');
  const [newNominalDefaultValueVolume, setNewNominalDefaultValueVolume] = useState<number | string>(
    ''
  );

  const [resetCountNominalValue, setResetCountNominalValue] = useState<number>(0);
  const [resetCountVolume, setResetCountVolume] = useState<number>(0);

  const {
    nominalValue: currentNominalValue,
    measurementType: measurementTypeWatched,
    volumeValue,
    densityValue,
    tareMode: tareModeWatched,
    singleTareMinValue: singleTareMinValueWatched,
    individualTareMinValue: individualTareMinValueWatched,
    tareValue,
  } = watch();
  const tareMode = tareModeWatched || article?.tareMode || TareMode.Preset;
  const measurementType =
    measurementTypeWatched ||
    (article?.volumeValue && MeasurementOption.Volume) ||
    MeasurementOption.Weight;

  useEffect(() => {
    if (article) {
      let hasFertigPackInputValues;
      switch (+tareMode) {
        case TareMode.Preset:
          hasFertigPackInputValues =
            (measurementType === MeasurementOption.Weight && !!nominalValueInput && !!tareValue) ||
            (measurementType === MeasurementOption.Volume &&
              !!nominalValueVolumeInput &&
              !!tareValue);
          break;
        case TareMode.Single:
        case TareMode.OrderedIndividual:
          hasFertigPackInputValues =
            (measurementType === MeasurementOption.Weight && !!nominalValueInput) ||
            (measurementType === MeasurementOption.Volume && !!nominalValueVolumeInput);
          break;
        default:
          hasFertigPackInputValues = false;
          break;
      }
      dispatch(changeArticleFertigPackInputValues(hasFertigPackInputValues));
      return () => {
        dispatch(changeArticleFertigPackInputValues(false));
      };
    }
  }, [
    dispatch,
    article,
    nominalValueInput,
    tareValue,
    nominalValueVolumeInput,
    measurementType,
    tareMode,
  ]);

  useEffect(() => {
    if (measurementOptionChanged) {
      setMeasurementOptionChanged(false);
      setNewNominalDefaultValueVolume('');
      setNewNominalDefaultValue('');
      setResetCountNominalValue((r) => r + 1);
      return;
    }

    const hasVolume = volumeValueInput && volumeValueInput !== '';
    const hasDensity = densityValueInput && densityValueInput !== '';

    if (hasVolume && hasDensity) {
      const newVolume = hasVolume ? Number(volumeValueInput) : 0;
      const newDensity = hasDensity ? Number(densityValueInput) : 0;
      setNewNominalDefaultValueVolume(Math.round(newVolume * newDensity * 1000) / 1000);
      setResetCountNominalValue((r) => r + 1);
    } else if (hasVolume || hasDensity) {
      setNewNominalDefaultValueVolume('');
      setResetCountNominalValue((r) => r + 1);
    }
  }, [volumeValueInput, densityValueInput, measurementOptionChanged]);

  const handleMeasurementTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeasurementOptionChanged(true);
    setResetCountVolume((r) => r + 1);
    setResetCountTolerances((r) => r + 1);
    clearTolerance();
  };

  const setLowerTolerance = (value: string) => {
    setLowerToleranceInput(value);
    setValue('lowerTolerance', value);
  };

  const setUpperTolerance = (value: string) => {
    setUpperToleranceInput(value);
    setValue('upperTolerance', value);
  };

  const setUpperToleranceVolume = (value: string) => {
    setUpperToleranceVolumeInput(value);
    setValue('upperToleranceVolume', value);
  };

  const setLowerToleranceVolume = (value: string) => {
    setLowerToleranceVolumeInput(value);
    setValue('lowerToleranceVolume', value);
  };

  const setSingleTareMaxValue = (value: string) => {
    setSingleTareMaxValueInput(value);
    setValue('singleTareMaxValue', value);
  };

  const setSingleTareMinValue = (value: string) => {
    setSingleTareMinValueInput(value);
    setValue('singleTareMinValue', value);
  };

  const setIndividualTareMaxValue = (value: string) => {
    setIndividualTareMaxValueInput(value);
    setValue('individualTareMaxValue', value);
  };

  const setIndividualTareMinValue = (value: string) => {
    setIndividualTareMinValueInput(value);
    setValue('individualTareMinValue', value);
  };

  const nominalValueInputChanged = (value: string) => {
    if (nominalValueInput !== value) {
      setNominalValueInput(value);
      setValue('nominalValue', value);
      resetTolerances();
      if (value !== '') {
        setShouldGetTolerances(true);
      }
    }
  };

  const nominalValueVolumeInputChanged = (value: string) => {
    if (nominalValueVolumeInput !== value) {
      setNominalValueVolumeInput(value);
      setValue('nominalValueVolume', value);
      resetTolerances();
      if (value !== '') {
        setShouldGetTolerances(true);
      }
    }
  };

  const tareValueInputChanged = (value: string) => {
    if (tareValueInput !== value) {
      setTareValueInput(value);
      setValue('tareValue', value);
    }
  };

  const volumeInputChanged = (value: string) => {
    if (volumeValueInput !== value) {
      setVolumeValueInput(value);
      setValue('volumeValue', value);
    }
  };

  const densityInputChanged = (value: string) => {
    if (densityValueInput !== value) {
      setDensityValueInput(value);
      setValue('densityValue', value);
    }
  };

  const lowerToleranceInputChanged = (value: string) => {
    if (lowerToleranceInput !== value) {
      setLowerTolerance(value);
    }
  };

  const upperToleranceInputChanged = (value: string) => {
    if (upperToleranceInput !== value) {
      setUpperTolerance(value);
    }
  };

  const lowerToleranceVolumeInputChanged = (value: string) => {
    if (lowerToleranceVolumeInput !== value) {
      setLowerToleranceVolume(value);
    }
  };

  const upperToleranceVolumeInputChanged = (value: string) => {
    if (upperToleranceVolumeInput !== value) {
      setUpperToleranceVolume(value);
    }
  };

  const singleTareMinValueChanged = (value: string) => {
    if (singleTareMinValueInput !== value) {
      setSingleTareMinValue(value);
    }
  };

  const singleTareMaxValueChanged = (value: string) => {
    if (singleTareMaxValueInput !== value) {
      setSingleTareMaxValue(value);
    }
  };

  const individualTareMinValueChanged = (value: string) => {
    if (individualTareMinValueInput !== value) {
      setIndividualTareMinValue(value);
    }
  };

  const individualTareMaxValueChanged = (value: string) => {
    if (individualTareMaxValueInput !== value) {
      setIndividualTareMaxValue(value);
    }
  };

  useEffect(() => {
    if (tareModeSwitched) {
      setValue('tareValue', '');
      setValue('tareSampleSize', '1');
      setValue('tareDeterminationMode', TareDeterminationMode.BeforeStart);
    }
  }, [tareModeSwitched, tareMode, setValue]);

  useEffect(() => {
    if (!tareModeSwitched && tareMode !== undefined && +tareMode !== articleTareMode) {
      setTareModeSwitched(true);
    }
  }, [tareModeSwitched, tareMode, articleTareMode, setTareModeSwitched]);

  const nominalValueComponent = () => {
    return (
      <GridItem s={4} xl={6}>
        <Controller
          name="nominalValue"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              debounce={300}
              key={'nominalValueComponent_' + resetCountNominalValue}
              name={'nominalValue'}
              onInputChange={nominalValueInputChanged}
              defaultValue={
                resetCountNominalValue === 0 && measurementType === MeasurementOption.Weight
                  ? article?.nominalValue?.toString() || ''
                  : newNominalDefaultValue.toString()
              }
              label={t('data:article.nominalValue')}
              ariaLabel={t('data:article.nominalValue')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              hasError={errors.nominalValue !== undefined}
              errorMessage={errors.nominalValue?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: { value: 5.0, message: t('form:minValue', { min: 5.0 }) },
            max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
          }}
        />
      </GridItem>
    );
  };

  const lowerToleranceComponent = () => {
    return (
      <GridItem s={4} xl={6}>
        <Controller
          name="lowerTolerance"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'lowerToleranceComponent_' + resetCountTolerances}
              name={'lowerTolerance'}
              onInputChange={lowerToleranceInputChanged}
              defaultValue={
                resetCountTolerances === 0 && measurementType === MeasurementOption.Weight
                  ? article?.lowerTolerance?.toString() || ''
                  : ''
              }
              label={t('data:article.lowerToleranceCustom')}
              ariaLabel={t('data:article.lowerToleranceCustom')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              disabled={
                !currentNominalValue || currentNominalValue === '' || nominalValueInput === ''
              }
              hasError={errors.lowerTolerance !== undefined}
              errorMessage={errors.lowerTolerance?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: {
              value: currentWeightTolerances?.tU1 || 0,
              message: t('form:minValue', {
                min: currentWeightTolerances?.tU1 || 0,
              }),
            },
            max: {
              value: Number(currentNominalValue) - 0.001,
              message: t('form:maxValue', { max: Number(currentNominalValue) - 0.001 }),
            },
          }}
        />
      </GridItem>
    );
  };

  const upperToleranceComponent = () => {
    return (
      <GridItem s={4} xl={6}>
        <Controller
          name="upperTolerance"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'upperToleranceComponent_' + resetCountTolerances}
              name={'upperTolerance'}
              onInputChange={upperToleranceInputChanged}
              defaultValue={
                resetCountTolerances === 0 && measurementType === MeasurementOption.Weight
                  ? article?.upperTolerance?.toString() || ''
                  : ''
              }
              label={t('data:article.upperToleranceCustom')}
              ariaLabel={t('data:article.upperToleranceCustom')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              disabled={
                !currentNominalValue || currentNominalValue === '' || nominalValueInput === ''
              }
              hasError={errors.upperTolerance !== undefined}
              errorMessage={errors.upperTolerance?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: {
              value: Number(currentNominalValue) + 0.001,
              message: t('form:minValue', { min: Number(currentNominalValue) + 0.001 }),
            },
            max: {
              value: currentWeightTolerances?.tO1 || 0,
              message: t('form:maxValue', {
                max: currentWeightTolerances?.tO1 || 0,
              }),
            },
          }}
        />
      </GridItem>
    );
  };

  const nominalValueVolumeComponent = () => {
    return (
      <GridItem xl={6}>
        <Controller
          name="nominalValueVolume"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              debounce={300}
              key={'nominalValueComponent_Volume_' + resetCountNominalValue}
              name={'nominalValueVolume'}
              onInputChange={nominalValueVolumeInputChanged}
              defaultValue={
                resetCountNominalValue === 0 && measurementType === MeasurementOption.Volume
                  ? article?.nominalValue?.toString() || ''
                  : newNominalDefaultValueVolume.toString()
              }
              label={t('data:article.nominalValue')}
              ariaLabel={t('data:article.nominalValue')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              disabled={true}
              hasError={errors.nominalValueVolume !== undefined}
              errorMessage={errors.nominalValueVolume?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: { value: 5.0, message: t('form:minValue', { min: 5.0 }) },
            max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
          }}
        />
      </GridItem>
    );
  };

  const volumeValueComponent = () => {
    return (
      <GridItem s={6}>
        <Controller
          name="volumeValue"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              debounce={300}
              key={'volumeValueInput_' + resetCountVolume}
              name={'volumeValue'}
              onInputChange={volumeInputChanged}
              defaultValue={resetCountVolume === 0 ? article?.volumeValue?.toString() || '' : ''}
              label={t('data:article.volume')}
              ariaLabel={t('data:article.volume')}
              fullWidth={true}
              suffixUnit={VOLUME_ENTITY}
              hasError={errors.volumeValue !== undefined}
              errorMessage={errors.volumeValue?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            required: {
              value: densityValue && densityValue !== '' ? true : false,
              message: t('form:fieldIsRequired'),
            },
            min: { value: 5.0, message: t('form:minValue', { min: 5.0 }) },
            max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
          }}
        />
      </GridItem>
    );
  };

  const densityValueComponent = () => {
    return (
      <GridItem s={6}>
        <Controller
          name="densityValue"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              debounce={300}
              key={'densityValueInput_' + resetCountVolume}
              name={'densityValue'}
              onInputChange={densityInputChanged}
              defaultValue={resetCountVolume === 0 ? article?.densityValue?.toString() || '' : ''}
              label={t('data:article.density')}
              ariaLabel={t('data:article.density')}
              fullWidth={true}
              suffixUnit={DENSITY_ENTITY}
              hasError={errors.densityValue !== undefined}
              errorMessage={errors.densityValue?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            required: {
              value: volumeValue && volumeValue !== '' ? true : false,
              message: t('form:fieldIsRequired'),
            },
            min: { value: 0.001, message: t('form:minValue', { min: 0.001 }) },
            max: { value: 25.0, message: t('form:maxValue', { max: 25.0 }) },
          }}
        />
      </GridItem>
    );
  };

  const lowerToleranceVolumeComponent = () => {
    return (
      <GridItem s={4} xl={6}>
        <Controller
          name="lowerToleranceVolume"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'lowerToleranceComponent_Volume_' + resetCountTolerances}
              name={'lowerToleranceVolume'}
              onInputChange={lowerToleranceVolumeInputChanged}
              defaultValue={
                resetCountTolerances === 0 && measurementType === MeasurementOption.Volume
                  ? article?.lowerTolerance?.toString() || ''
                  : ''
              }
              label={t('data:article.lowerToleranceCustom')}
              ariaLabel={t('data:article.lowerToleranceCustom')}
              fullWidth={true}
              suffixUnit={VOLUME_ENTITY}
              disabled={!newNominalDefaultValueVolume || newNominalDefaultValueVolume === ''}
              hasError={errors.lowerToleranceVolume !== undefined}
              errorMessage={errors.lowerToleranceVolume?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: {
              value: currentVolumeTolerances?.tU1 || 0,
              message: t('form:minValue', {
                min: currentVolumeTolerances?.tU1 || 0,
              }),
            },
            max: {
              value: Number(volumeValue) - 0.001,
              message: t('form:maxValue', { max: Number(volumeValue) - 0.001 }),
            },
          }}
        />
      </GridItem>
    );
  };

  const upperToleranceVolumeComponent = () => {
    return (
      <GridItem s={4} xl={6}>
        <Controller
          name="upperToleranceVolume"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'upperToleranceComponent_Volume_' + resetCountTolerances}
              name={'upperToleranceVolume'}
              onInputChange={upperToleranceVolumeInputChanged}
              defaultValue={
                resetCountTolerances === 0 && measurementType === MeasurementOption.Volume
                  ? article?.upperTolerance?.toString() || ''
                  : ''
              }
              label={t('data:article.upperToleranceCustom')}
              ariaLabel={t('data:article.upperToleranceCustom')}
              fullWidth={true}
              suffixUnit={VOLUME_ENTITY}
              disabled={!newNominalDefaultValueVolume || newNominalDefaultValueVolume === ''}
              hasError={errors.upperToleranceVolume !== undefined}
              errorMessage={errors.upperToleranceVolume?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: {
              value: Number(volumeValue) + 0.001,
              message: t('form:minValue', { min: Number(volumeValue) + 0.001 }),
            },
            max: {
              value: currentVolumeTolerances?.tO1 || 0,
              message: t('form:maxValue', {
                max: currentVolumeTolerances?.tO1 || 0,
              }),
            },
          }}
        />
      </GridItem>
    );
  };

  const tareValueComponent = () => {
    return (
      <GridItem s={4} xl={6}>
        <Controller
          name="tareValue"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'tareValueInput_' + tareMode}
              name={'tareValue'}
              onInputChange={tareValueInputChanged}
              defaultValue={
                tareModeSwitched || article?.tareMode !== TareMode.Preset
                  ? ''
                  : article?.tareValue?.toString() || ''
              }
              label={t('data:article.tare')}
              ariaLabel={t('data:article.tare')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              hasError={errors.tareValue !== undefined}
              errorMessage={errors.tareValue?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: { value: 0.0, message: t('form:minValue', { min: 0.0 }) },
            max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
          }}
        />
      </GridItem>
    );
  };

  const tareSampleSizeComponent = () => {
    return (
      <GridItem xl={6}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth={true}
              required
              label={t('data:article.tareSampleSize')}
              inputProps={{ 'aria-label': t('data:article.tareSampleSize') }}
              error={errors.tareSampleSize !== undefined}
              helperText={errors.tareSampleSize?.message}
            />
          )}
          control={control}
          name="tareSampleSize"
          defaultValue={
            article?.tareMode === TareMode.Single && article?.tareSampleSize
              ? article?.tareSampleSize?.toString()
              : '1'
          }
          rules={{
            required: { value: true, message: t('form:fieldIsRequired') },
            validate: (value: string | number) =>
              Number.isInteger(Number(value)) ? true : (t('form:integerValue') as string),
            min: { value: 1, message: t('form:minValue', { min: 1 }) },
            max: { value: 20, message: t('form:maxValue', { max: 20 }) },
          }}
        />
      </GridItem>
    );
  };

  const tareIndividualComponent = () => {
    return (
      <GridItem s={8} xl={12}>
        <Typography>{t('data:article.tareIndividualDescription')}</Typography>
      </GridItem>
    );
  };

  const singleTareMaxValueComponent = () => {
    return (
      <GridItem s={6}>
        <Controller
          name="singleTareMaxValue"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'singleTareMaxValueComponent' + tareMode}
              name={'singleTareMaxValue'}
              onInputChange={singleTareMaxValueChanged}
              defaultValue={
                tareModeSwitched || article?.tareMode !== TareMode.Single
                  ? ''
                  : article?.tareMaxValue?.toString() || ''
              }
              label={t('data:article.tareMaxValueCustom')}
              ariaLabel={t('data:article.tareMaxValueCustom')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              hasError={errors.singleTareMaxValue !== undefined}
              errorMessage={errors.singleTareMaxValue?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: {
              value:
                singleTareMinValueWatched || singleTareMinValueWatched === 0
                  ? Number(singleTareMinValueWatched) + 0.001
                  : 0.0,
              message: t('form:minValue', {
                min:
                  singleTareMinValueWatched === 0
                    ? 0.001
                    : singleTareMinValueWatched
                    ? (Number(singleTareMinValueWatched) + 0.001).toFixed(3)
                    : 0.0,
              }),
            },
            max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
          }}
        />
      </GridItem>
    );
  };

  const singleTareMinValueComponent = () => {
    return (
      <GridItem s={6}>
        <Controller
          name="singleTareMinValue"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'singleTareMinValueComponent' + tareMode}
              name={'singleTareMinValue'}
              onInputChange={singleTareMinValueChanged}
              defaultValue={
                tareModeSwitched || article?.tareMode !== TareMode.Single
                  ? ''
                  : article?.tareMinValue?.toString() || ''
              }
              label={t('data:article.tareMinValueCustom')}
              ariaLabel={t('data:article.tareMinValueCustom')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              hasError={errors.singleTareMinValue !== undefined}
              errorMessage={errors.singleTareMinValue?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: { value: 0.0, message: t('form:minValue', { min: 0.0 }) },
            max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
          }}
        />
      </GridItem>
    );
  };

  const individualTareMaxValueComponent = () => {
    return (
      <GridItem s={4} xl={6}>
        <Controller
          name="individualTareMaxValue"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'individualTareMaxValueComponent' + tareMode}
              name={'individualTareMaxValue'}
              onInputChange={individualTareMaxValueChanged}
              defaultValue={
                tareModeSwitched || article?.tareMode !== TareMode.OrderedIndividual
                  ? ''
                  : article?.tareMaxValue?.toString() || ''
              }
              label={t('data:article.tareMaxValueCustom')}
              ariaLabel={t('data:article.tareMaxValueCustom')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              hasError={errors.individualTareMaxValue !== undefined}
              errorMessage={errors.individualTareMaxValue?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: {
              value:
                individualTareMinValueWatched || individualTareMinValueWatched === 0
                  ? Number(individualTareMinValueWatched) + 0.001
                  : 0.0,
              message: t('form:minValue', {
                min:
                  individualTareMinValueWatched === 0
                    ? 0.001
                    : individualTareMinValueWatched
                    ? (Number(individualTareMinValueWatched) + 0.001).toFixed(3)
                    : 0.0,
              }),
            },
            max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
          }}
        />
      </GridItem>
    );
  };

  const individualTareMinValueComponent = () => {
    return (
      <GridItem s={4} xl={6}>
        <Controller
          name="individualTareMinValue"
          control={control}
          render={() => (
            <FormattedTextFieldComponent
              key={'individualTareMinValueComponent' + tareMode}
              name={'individualTareMinValue'}
              onInputChange={individualTareMinValueChanged}
              defaultValue={
                tareModeSwitched || article?.tareMode !== TareMode.OrderedIndividual
                  ? ''
                  : article?.tareMinValue?.toString() || ''
              }
              label={t('data:article.tareMinValueCustom')}
              ariaLabel={t('data:article.tareMinValueCustom')}
              fullWidth={true}
              suffixUnit={WEIGHT_ENTITY}
              hasError={errors.individualTareMinValue !== undefined}
              errorMessage={errors.individualTareMinValue?.message}
            />
          )}
          rules={{
            validate: (value: string | number) => {
              return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
            },
            min: { value: 0.0, message: t('form:minValue', { min: 0.0 }) },
            max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
          }}
        />
      </GridItem>
    );
  };

  return (
    <>
      <Typography variant="h2">{t('data:article.nominalCapacity')}</Typography>
      <GridContainer level={GridLevel.Page}>
        <GridItem xl={6}>
          <Paper className={articleFormClasses.equalHeightContainer}>
            <ContainerInside>
              <GridContainer level={GridLevel.InputPaper}>
                <GridItem>
                  <FormControl fullWidth={true}>
                    <Typography variant="h3">{t('data:article.measurementType')}</Typography>
                    <Controller
                      control={control}
                      name="measurementType"
                      rules={{
                        required: { value: true, message: t('form:fieldIsRequired') },
                      }}
                      render={({ field }) => (
                        <RadioGroup {...field} aria-label={t('data:article.measurementType')}>
                          <FormControlLabel
                            data-testid="weightRadioBtn"
                            value={MeasurementOption.Weight}
                            control={<Radio onChange={handleMeasurementTypeChange} />}
                            label={t('data:article.weight') as string}
                          />
                          <Box
                            className={
                              measurementType === MeasurementOption.Weight
                                ? articleFormClasses.radioContent
                                : articleFormClasses.hidden
                            }
                            data-testid="weightRadioContent"
                          >
                            <GridContainer level={GridLevel.InputPaper}>
                              <GridItem>
                                <GridContainer level={GridLevel.InputPaper}>
                                  {nominalValueComponent()}
                                </GridContainer>
                              </GridItem>

                              <GridItem>
                                <GridContainer level={GridLevel.InputPaper}>
                                  {lowerToleranceComponent()}
                                  {upperToleranceComponent()}
                                </GridContainer>
                              </GridItem>
                            </GridContainer>
                          </Box>
                          <FormControlLabel
                            data-testid="volumeRadioBtn"
                            value={MeasurementOption.Volume}
                            control={<Radio onChange={handleMeasurementTypeChange} />}
                            label={t('data:article.volume') as string}
                          />
                          <Box
                            className={
                              measurementType === MeasurementOption.Volume
                                ? articleFormClasses.radioContentLastRow
                                : articleFormClasses.hidden
                            }
                            data-testid="volumeRadioContent"
                          >
                            <GridContainer level={GridLevel.InputPaper}>
                              <GridItem s={4} xl={12}>
                                <GridContainer level={GridLevel.InputPaper}>
                                  {nominalValueVolumeComponent()}
                                </GridContainer>
                              </GridItem>
                              <GridItem s={8} xl={12}>
                                <GridContainer level={GridLevel.InputPaper}>
                                  {volumeValueComponent()}
                                  {densityValueComponent()}
                                </GridContainer>
                              </GridItem>

                              <GridItem>
                                <GridContainer level={GridLevel.InputPaper}>
                                  {lowerToleranceVolumeComponent()}
                                  {upperToleranceVolumeComponent()}
                                </GridContainer>
                              </GridItem>
                            </GridContainer>
                          </Box>
                        </RadioGroup>
                      )}
                    />
                    {errors.measurementType && errors.measurementType.message && (
                      <FormHelperText>{errors.measurementType.message}</FormHelperText>
                    )}
                  </FormControl>
                </GridItem>
              </GridContainer>
            </ContainerInside>
          </Paper>
        </GridItem>
        <GridItem xl={6}>
          <Paper className={articleFormClasses.equalHeightContainer}>
            <ContainerInside>
              <FormControl fullWidth={true}>
                <Typography variant="h3">{t('data:article.tare')}</Typography>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field} aria-label={t('data:article.tareMode')} name="tareMode">
                      <FormControlLabel
                        data-testid="tarePresetRadioBtn"
                        value={TareMode.Preset}
                        control={<Radio />}
                        label={t('data:article.tarePreset') as string}
                      />
                      <Box
                        className={
                          +tareMode === TareMode.Preset
                            ? articleFormClasses.radioContent
                            : articleFormClasses.hidden
                        }
                      >
                        <GridContainer level={GridLevel.InputPaper}>
                          {tareValueComponent()}
                        </GridContainer>
                      </Box>
                      <FormControlLabel
                        data-testid="tareDeterminationRadioBtn"
                        value={TareMode.Single}
                        control={<Radio />}
                        label={t('data:article.tareDetermination') as string}
                      />
                      <Box
                        className={
                          +tareMode === TareMode.Single
                            ? articleFormClasses.radioContent
                            : articleFormClasses.hidden
                        }
                      >
                        <Controller
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              name="tareDeterminationMode"
                              className={articleFormClasses.radioGroup}
                            >
                              <FormControlLabel
                                value={TareDeterminationMode.BeforeStart}
                                control={<Radio />}
                                label={
                                  t([
                                    `data:article.tareDeterminationMode.${TareDeterminationMode.BeforeStart}`,
                                    'data:article.tareDeterminationMode.notFound',
                                  ]) as string
                                }
                                data-testid="beforeStartBtn"
                              />
                              <FormControlLabel
                                value={TareDeterminationMode.BeforeChecks}
                                control={<Radio />}
                                label={
                                  t([
                                    `data:article.tareDeterminationMode.${TareDeterminationMode.BeforeChecks}`,
                                    'data:article.tareDeterminationMode.notFound',
                                  ]) as string
                                }
                                data-testid="beforeChecksBtn"
                              />
                            </RadioGroup>
                          )}
                          control={control}
                          name="tareDeterminationMode"
                          rules={{
                            required: {
                              value: tareModeWatched === TareMode.Single,
                              message: t('form:fieldIsRequired'),
                            },
                          }}
                        />
                        <GridContainer level={GridLevel.InputPaper}>
                          <GridItem s={4} xl={12}>
                            <GridContainer level={GridLevel.InputPaper}>
                              {tareSampleSizeComponent()}
                            </GridContainer>
                          </GridItem>
                          <GridItem s={8} xl={12}>
                            <GridContainer level={GridLevel.InputPaper}>
                              {singleTareMinValueComponent()}
                              {singleTareMaxValueComponent()}
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </Box>
                      <FormControlLabel
                        data-testid="tareIndividualRadioBtn"
                        value={TareMode.OrderedIndividual}
                        control={<Radio />}
                        label={t('data:article.tareIndividual') as string}
                      />
                      <Box
                        className={
                          +tareMode === TareMode.OrderedIndividual
                            ? articleFormClasses.radioContentLastRow
                            : articleFormClasses.hidden
                        }
                      >
                        <GridContainer level={GridLevel.InputPaper}>
                          {tareIndividualComponent()}
                          <GridItem>
                            <GridContainer level={GridLevel.InputPaper}>
                              {individualTareMinValueComponent()}
                              {individualTareMaxValueComponent()}
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </Box>
                    </RadioGroup>
                  )}
                  control={control}
                  name="tareMode"
                  rules={{
                    required: { value: true, message: t('form:fieldIsRequired') },
                  }}
                />
              </FormControl>
            </ContainerInside>
          </Paper>
        </GridItem>
      </GridContainer>
    </>
  );
};
