import { Autocomplete, Paper, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ResponsiveImageInputPreviewComponent } from '../../../../components/image/responsive-image/responsive-image-input-preview.component';
import { ContainerInside } from '../../../../components/structure';
import { Line, NewArticle } from '../../../../model';

export interface OwnProps {
  chosenImageFile?: File | undefined;
  setChosenImageFile: (file: File | undefined) => void;
  removeImage: () => void;
  lines: Line[];
  imageUrl?: string;
  article?: NewArticle;
}

export const ArticleFormBaseDataComponent = (props: OwnProps) => {
  const {
    formState: { errors },
    control,
    register,
    setValue,
  } = useFormContext();
  const { t } = useTranslation(['data']);
  const { chosenImageFile, removeImage, setChosenImageFile, imageUrl, article } = props;

  const handleLineChange = (e: React.ChangeEvent<{}>, value: Line | null) => {
    setValue('productionLine', value || undefined);
  };

  useEffect(() => {
    // Autocomplete needs custom register
    register('productionLine');
  });

  return (
    <>
      <Typography variant="h2">{t('data:article.baseData')}</Typography>
      <Paper>
        <ContainerInside>
          <GridContainer level={GridLevel.InputPaper}>
            <GridItem s={6} xl={4}>
              <ResponsiveImageInputPreviewComponent
                changeImage={setChosenImageFile}
                chosenImageFile={chosenImageFile}
                removeImage={removeImage}
                imageUrl={imageUrl}
              />
            </GridItem>
            <GridItem s={6} xl={8}>
              <GridContainer level={GridLevel.InputPaper}>
                <GridItem>
                  <GridContainer level={GridLevel.InputPaper}>
                    <GridItem xl={6}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth={true}
                            required
                            label={t('data:article.number')}
                            inputProps={{ 'aria-label': t('data:article.number') }}
                            error={errors.articleNumber !== undefined}
                            helperText={errors.articleNumber && errors.articleNumber.message}
                          />
                        )}
                        control={control}
                        name="articleNumber"
                        rules={{
                          required: { value: true, message: t('form:fieldIsRequired') },
                          validate: (value: string) =>
                            value.trim() ? true : (t('form:fieldIsRequired') as string),
                          minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
                          maxLength: {
                            value: 30,
                            message: t('form:maxLength', { max: '30' }),
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xl={6}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth={true}
                            required
                            label={t('data:article.name')}
                            inputProps={{ 'aria-label': t('data:article.name') }}
                            error={errors.articleName !== undefined}
                            helperText={errors.articleName && errors.articleName.message}
                          />
                        )}
                        control={control}
                        name="articleName"
                        rules={{
                          required: { value: true, message: t('form:fieldIsRequired') },
                          validate: (value: string) =>
                            value.trim() ? true : (t('form:fieldIsRequired') as string),
                          minLength: { value: 2, message: t('form:minLength', { min: '2' }) },
                          maxLength: {
                            value: 100,
                            message: t('form:maxLength', { max: '100' }),
                          },
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>
                  <GridContainer level={GridLevel.InputPaper}>
                    <GridItem xl={6}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth={true}
                            label={t('data:article.nameSuffix')}
                            inputProps={{ 'aria-label': t('data:article.nameSuffix') }}
                            error={errors.articleNameSuffix !== undefined}
                            helperText={errors.articleNameSuffix?.message}
                          />
                        )}
                        control={control}
                        name="articleNameSuffix"
                        rules={{
                          minLength: { value: 2, message: t('form:minLength', { min: '2' }) },
                          maxLength: {
                            value: 100,
                            message: t('form:maxLength', { max: '100' }),
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xl={6}>
                      {/* Does not work with Controller yet because of it's nested structure */}
                      <Autocomplete
                        options={props.lines || []}
                        getOptionLabel={(line: Line) => line.name}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                        defaultValue={article?.productionLine || undefined}
                        isOptionEqualToValue={(option: Line, value: Line) => option.id === value.id}
                        clearText={t('common:delete')}
                        noOptionsText={t('common:noDataAvailable')}
                        openText={t('common:open')}
                        closeText={t('common:close')}
                        id="productionLineAutocomplete"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="productionLine"
                            label={t('data:article.defaultLine')}
                            inputProps={{
                              ...params.inputProps,
                              'data-testid': 'lineInput',
                            }}
                            error={!!errors.productionLine}
                            helperText={
                              errors.productionLine && (errors.productionLine as any).message
                            }
                          />
                        )}
                        onChange={handleLineChange}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ContainerInside>
      </Paper>
    </>
  );
};
