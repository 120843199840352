import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormattedTextFieldComponent } from '../../../components/formatted-text-field/formatted-text-field.component';
import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { ContainerInside } from '../../../components/structure';
import { WEIGHT_ENTITY } from '../../../constants';
import { Scale } from '../../../model';

export interface OwnProps {
  device?: Scale;
}

export const WeighingBridgeComponent = (props: OwnProps) => {
  const { device } = props;
  const { t } = useTranslation(['data', 'form']);
  const {
    formState: { errors },
    setValue,
    register,
    watch,
  } = useFormContext();

  const hasBridge1 = device?.weighingBridges && device.weighingBridges.length > 0;
  const bridge1 = hasBridge1 ? device.weighingBridges![0] : undefined;

  const hasBridge2 = device?.weighingBridges && device.weighingBridges.length > 1;
  const bridge2 = hasBridge2 ? device.weighingBridges![1] : undefined;

  const [minimalLoad11, setMinimalLoad11] = useState<string | undefined>(
    !!bridge1 && bridge1.firstMinimalLoad ? bridge1.firstMinimalLoad.toString() : ''
  );
  const [minimalLoad12, setMinimalLoad12] = useState<string | undefined>(
    !!bridge1 && bridge1.secondMinimalLoad ? bridge1.secondMinimalLoad.toString() : ''
  );
  const [minimalLoad13, setMinimalLoad13] = useState<string | undefined>(
    !!bridge1 && bridge1.thirdMinimalLoad ? bridge1.thirdMinimalLoad.toString() : ''
  );
  const [minimalLoad21, setMinimalLoad21] = useState<string | undefined>(
    !!bridge2 && bridge2.firstMinimalLoad ? bridge2.firstMinimalLoad.toString() : ''
  );
  const [minimalLoad22, setMinimalLoad22] = useState<string | undefined>(
    !!bridge2 && bridge2.secondMinimalLoad ? bridge2.secondMinimalLoad.toString() : ''
  );
  const [minimalLoad23, setMinimalLoad23] = useState<string | undefined>(
    !!bridge2 && bridge2.thirdMinimalLoad ? bridge2.thirdMinimalLoad.toString() : ''
  );

  const [maximalLoad11, setMaximalLoad11] = useState<string | undefined>(
    !!bridge1 && bridge1.firstMaximalLoad ? bridge1.firstMaximalLoad.toString() : ''
  );
  const [maximalLoad12, setMaximalLoad12] = useState<string | undefined>(
    !!bridge1 && bridge1.secondMaximalLoad ? bridge1.secondMaximalLoad.toString() : ''
  );
  const [maximalLoad13, setMaximalLoad13] = useState<string | undefined>(
    !!bridge1 && bridge1.thirdMaximalLoad ? bridge1.thirdMaximalLoad.toString() : ''
  );
  const [maximalLoad21, setMaximalLoad21] = useState<string | undefined>(
    !!bridge2 && bridge2.firstMaximalLoad ? bridge2.firstMaximalLoad.toString() : ''
  );
  const [maximalLoad22, setMaximalLoad22] = useState<string | undefined>(
    !!bridge2 && bridge2.secondMaximalLoad ? bridge2.secondMaximalLoad.toString() : ''
  );
  const [maximalLoad23, setMaximalLoad23] = useState<string | undefined>(
    !!bridge2 && bridge2.thirdMaximalLoad ? bridge2.thirdMaximalLoad.toString() : ''
  );

  const [digitStep11, setDigitStep11] = useState<string | undefined>(
    !!bridge1 && bridge1.firstDigitStep ? bridge1.firstDigitStep.toString() : ''
  );
  const [digitStep12, setDigitStep12] = useState<string | undefined>(
    !!bridge1 && bridge1.secondDigitStep ? bridge1.secondDigitStep.toString() : ''
  );
  const [digitStep13, setDigitStep13] = useState<string | undefined>(
    !!bridge1 && bridge1.thirdDigitStep ? bridge1.thirdDigitStep.toString() : ''
  );
  const [digitStep21, setDigitStep21] = useState<string | undefined>(
    !!bridge2 && bridge2.firstDigitStep ? bridge2.firstDigitStep.toString() : ''
  );
  const [digitStep22, setDigitStep22] = useState<string | undefined>(
    !!bridge2 && bridge2.secondDigitStep ? bridge2.secondDigitStep.toString() : ''
  );
  const [digitStep23, setDigitStep23] = useState<string | undefined>(
    !!bridge2 && bridge2.thirdDigitStep ? bridge2.thirdDigitStep.toString() : ''
  );

  useEffect(() => {
    // FormattedTextField for minimalLoad11 needs custom register
    register('minimalLoad11', {
      validate: (value: string) => (value ? true : (t('form:fieldIsRequired') as string)),
      min: {
        value: 0.001,
        message: t('form:minValue', { min: 0.001 }),
      },
    });
    // FormattedTextField for maximalLoad11 needs custom register
    register('maximalLoad11', {
      validate: (value: string) => (value ? true : (t('form:fieldIsRequired') as string)),
      min: {
        value: Number(minimalLoad11Watched) + 0.001,
        message: t('form:minValue', { min: Number(minimalLoad11Watched) + 0.001 }),
      },
    });
    // FormattedTextField for digitStep11 needs custom register
    register('digitStep11', {
      validate: (value: string) => {
        return Number(maximalLoad11Watched) > 0 && Number(maximalLoad11Watched) <= Number(value)
          ? (t('form:maxValue', { max: Number(maximalLoad11Watched) - 0.001 }) as string)
          : value
          ? true
          : (t('form:fieldIsRequired') as string);
      },
      min: {
        value: 0.001,
        message: t('form:minValue', { min: 0.001 }),
      },
    });

    // FormattedTextField for minimalLoad12 needs custom register
    register('minimalLoad12', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
    });
    // FormattedTextField for maximalLoad12 needs custom register
    register('maximalLoad12', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
      min: {
        value: Number(minimalLoad12Watched) + 0.001,
        message: t('form:minValue', { min: Number(minimalLoad12Watched) + 0.001 }),
      },
      required: {
        value: !!digitStep12Watched,
        message: t('form:fieldIsRequired', { required: digitStep12Watched }),
      },
    });
    // FormattedTextField for digitStep12 needs custom register
    register('digitStep12', {
      validate: (value: string) => {
        return Number(maximalLoad12Watched) > 0 && Number(maximalLoad12Watched) <= Number(value)
          ? (t('form:maxValue', { max: Number(maximalLoad12Watched) - 0.001 }) as string)
          : !Number.isNaN(Number(value))
          ? true
          : (t('form:numberValue') as string);
      },
      min: {
        value: 0.001,
        message: t('form:minValue', { min: 0.001 }),
      },
      required: {
        value: !!maximalLoad12Watched,
        message: t('form:fieldIsRequired', {
          required: maximalLoad12Watched,
        }),
      },
    });

    // FormattedTextField for minimalLoad13 needs custom register
    register('minimalLoad13', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
    });
    // FormattedTextField for maximalLoad13 needs custom register
    register('maximalLoad13', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
      min: {
        value: Number(minimalLoad13Watched) + 0.001,
        message: t('form:minValue', { min: Number(minimalLoad13Watched) + 0.001 }),
      },
      required: {
        value: !!digitStep13Watched,
        message: t('form:fieldIsRequired', { required: digitStep13Watched }),
      },
    });
    // FormattedTextField for digitStep13 needs custom register
    register('digitStep13', {
      validate: (value: string) => {
        return Number(maximalLoad13Watched) > 0 && Number(maximalLoad13Watched) <= Number(value)
          ? (t('form:maxValue', { max: Number(maximalLoad13Watched) - 0.001 }) as string)
          : !Number.isNaN(Number(value))
          ? true
          : (t('form:numberValue') as string);
      },
      min: {
        value: 0.001,
        message: t('form:minValue', { min: 0.001 }),
      },
      required: {
        value: !!maximalLoad13Watched,
        message: t('form:fieldIsRequired', {
          required: maximalLoad13Watched,
        }),
      },
    });

    // FormattedTextField for minimalLoad21 needs custom register
    register('minimalLoad21', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
      required: {
        value: !!digitStep21Watched || !!maximalLoad21Watched,
        message: t('form:fieldIsRequired', {
          required: digitStep21Watched || !!maximalLoad21Watched,
        }),
      },
      min: {
        value: 0.001,
        message: t('form:minValue', { min: 0.001 }),
      },
    });
    // FormattedTextField for maximalLoad21 needs custom register
    register('maximalLoad21', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
      min: {
        value: Number(minimalLoad21Watched) + 0.001,
        message: t('form:minValue', { min: Number(minimalLoad21Watched) + 0.001 }),
      },
      required: {
        value: !!digitStep21Watched || !!minimalLoad21Watched,
        message: t('form:fieldIsRequired', {
          required: digitStep21Watched || !!minimalLoad21Watched,
        }),
      },
    });
    // FormattedTextField for digitStep21 needs custom register
    register('digitStep21', {
      validate: (value: string) => {
        return Number(maximalLoad21Watched) > 0 && Number(maximalLoad21Watched) <= Number(value)
          ? (t('form:maxValue', { max: Number(maximalLoad21Watched) - 0.001 }) as string)
          : !Number.isNaN(Number(value))
          ? true
          : (t('form:numberValue') as string);
      },
      min: {
        value: 0.001,
        message: t('form:minValue', { min: 0.001 }),
      },
      required: {
        value: !!maximalLoad21Watched || !!minimalLoad21Watched,
        message: t('form:fieldIsRequired', {
          required: maximalLoad21Watched || !!minimalLoad21Watched,
        }),
      },
    });

    // FormattedTextField for minimalLoad22 needs custom register
    register('minimalLoad22', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
    });
    // FormattedTextField for maximalLoad22 needs custom register
    register('maximalLoad22', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
      min: {
        value: Number(minimalLoad22Watched) + 0.001,
        message: t('form:minValue', { min: Number(minimalLoad22Watched) + 0.001 }),
      },
      required: {
        value: !!digitStep22Watched,
        message: t('form:fieldIsRequired', {
          required: digitStep22Watched,
        }),
      },
    });
    // FormattedTextField for digitStep22 needs custom register
    register('digitStep22', {
      validate: (value: string) => {
        return Number(maximalLoad22Watched) > 0 && Number(maximalLoad22Watched) <= Number(value)
          ? (t('form:maxValue', { max: Number(maximalLoad22Watched) - 0.001 }) as string)
          : !Number.isNaN(Number(value))
          ? true
          : (t('form:numberValue') as string);
      },
      min: {
        value: 0.001,
        message: t('form:minValue', { min: 0.001 }),
      },
      required: {
        value: !!maximalLoad22Watched,
        message: t('form:fieldIsRequired', {
          required: maximalLoad22Watched,
        }),
      },
    });

    // FormattedTextField for minimalLoad23 needs custom register
    register('minimalLoad23', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
    });
    // FormattedTextField for maximalLoad23 needs custom register
    register('maximalLoad23', {
      validate: (value: string) => {
        return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
      },
      min: {
        value: Number(minimalLoad23Watched) + 0.001,
        message: t('form:minValue', { min: Number(minimalLoad23Watched) + 0.001 }),
      },
      required: {
        value: !!digitStep23Watched,
        message: t('form:fieldIsRequired', {
          required: digitStep23Watched,
        }),
      },
    });
    // FormattedTextField for digitStep23 needs custom register
    register('digitStep23', {
      validate: (value: string) => {
        return Number(maximalLoad23Watched) > 0 && Number(maximalLoad23Watched) <= Number(value)
          ? (t('form:maxValue', { max: Number(maximalLoad23Watched) - 0.001 }) as string)
          : !Number.isNaN(Number(value))
          ? true
          : (t('form:numberValue') as string);
      },
      min: {
        value: 0.001,
        message: t('form:minValue', { min: 0.001 }),
      },
      required: {
        value: !!maximalLoad23Watched,
        message: t('form:fieldIsRequired', {
          required: maximalLoad23Watched,
        }),
      },
    });
  });

  const {
    minimalLoad11: minimalLoad11Watched,
    minimalLoad12: minimalLoad12Watched,
    minimalLoad13: minimalLoad13Watched,
    minimalLoad21: minimalLoad21Watched,
    minimalLoad22: minimalLoad22Watched,
    minimalLoad23: minimalLoad23Watched,
    maximalLoad11: maximalLoad11Watched,
    maximalLoad12: maximalLoad12Watched,
    maximalLoad13: maximalLoad13Watched,
    maximalLoad21: maximalLoad21Watched,
    maximalLoad22: maximalLoad22Watched,
    maximalLoad23: maximalLoad23Watched,
    digitStep12: digitStep12Watched,
    digitStep13: digitStep13Watched,
    digitStep21: digitStep21Watched,
    digitStep22: digitStep22Watched,
    digitStep23: digitStep23Watched,
  } = watch();

  const area12IsDisabled = maximalLoad11 === '' || digitStep11 === '';
  const area13IsDisabled = maximalLoad12 === '' || digitStep12 === '' || area12IsDisabled;
  const area21IsDisabled = maximalLoad11 === '' || digitStep11 === '';
  const area22IsDisabled = maximalLoad21 === '' || digitStep21 === '' || area21IsDisabled;
  const area23IsDisabled = maximalLoad22 === '' || digitStep22 === '' || area22IsDisabled;

  const minimalLoadArea11Changed = (value: string) => {
    if (minimalLoad11 !== value) {
      setMinimalLoad11(value);
      setValue('minimalLoad11', value);
    }
  };

  const minimalLoadArea12Changed = (value: string) => {
    if (minimalLoad12 !== value) {
      setMinimalLoad12(value);
      setValue('minimalLoad12', value);
    }
  };

  const minimalLoadArea13Changed = (value: string) => {
    if (minimalLoad13 !== value) {
      setMinimalLoad13(value);
      setValue('minimalLoad13', value);
    }
  };

  const minimalLoadArea21Changed = (value: string) => {
    if (minimalLoad21 !== value) {
      setMinimalLoad21(value);
      setValue('minimalLoad21', value);
    }
  };

  const minimalLoadArea22Changed = (value: string) => {
    if (minimalLoad22 !== value) {
      setMinimalLoad22(value);
      setValue('minimalLoad22', value);
    }
  };

  const minimalLoadArea23Changed = (value: string) => {
    if (minimalLoad23 !== value) {
      setMinimalLoad23(value);
      setValue('minimalLoad23', value);
    }
  };

  const maximalLoadArea11Changed = (value: string) => {
    if (maximalLoad11 !== value) {
      setMaximalLoad11(value);
      setValue('maximalLoad11', value);
      setValue('minimalLoad12', value);
    }
  };

  const maximalLoadArea12Changed = (value: string) => {
    if (maximalLoad12 !== value) {
      setMaximalLoad12(value);
      setValue('maximalLoad12', value);
      setValue('minimalLoad13', value);
    }
  };

  const maximalLoadArea13Changed = (value: string) => {
    if (maximalLoad13 !== value) {
      setMaximalLoad13(value);
      setValue('maximalLoad13', value);
    }
  };

  const maximalLoadArea21Changed = (value: string) => {
    if (maximalLoad21 !== value) {
      setMaximalLoad21(value);
      setValue('maximalLoad21', value);
      setValue('minimalLoad22', value);
    }
  };

  const maximalLoadArea22Changed = (value: string) => {
    if (maximalLoad22 !== value) {
      setMaximalLoad22(value);
      setValue('maximalLoad22', value);
      setValue('minimalLoad23', value);
    }
  };

  const maximalLoadArea23Changed = (value: string) => {
    if (maximalLoad23 !== value) {
      setMaximalLoad23(value);
      setValue('maximalLoad23', value);
    }
  };

  const digitStepArea11Changed = (value: string) => {
    if (digitStep11 !== value) {
      setDigitStep11(value);
      setValue('digitStep11', value);
    }
  };

  const digitStepArea12Changed = (value: string) => {
    if (digitStep12 !== value) {
      setDigitStep12(value);
      setValue('digitStep12', value);
    }
  };

  const digitStepArea13Changed = (value: string) => {
    if (digitStep13 !== value) {
      setDigitStep13(value);
      setValue('digitStep13', value);
    }
  };

  const digitStepArea21Changed = (value: string) => {
    if (digitStep21 !== value) {
      setDigitStep21(value);
      setValue('digitStep21', value);
    }
  };

  const digitStepArea22Changed = (value: string) => {
    if (digitStep22 !== value) {
      setDigitStep22(value);
      setValue('digitStep22', value);
    }
  };

  const digitStepArea23Changed = (value: string) => {
    if (digitStep23 !== value) {
      setDigitStep23(value);
      setValue('digitStep23', value);
    }
  };

  useEffect(() => {
    if (area13IsDisabled) {
      setValue('maximalLoad13', '');
      setValue('digitStep13', '');
    } else {
      setValue('maximalLoad13', maximalLoad13?.toString());
      setValue('digitStep13', digitStep13?.toString());
    }
    if (area22IsDisabled) {
      setValue('maximalLoad22', '');
      setValue('digitStep22', '');
      setValue('maximalLoad23', '');
      setValue('digitStep23', '');
    } else {
      setValue('maximalLoad22', maximalLoad22?.toString());
      setValue('digitStep22', digitStep22?.toString());
      setValue('maximalLoad23', maximalLoad23?.toString());
      setValue('digitStep23', digitStep23?.toString());
    }
    if (area23IsDisabled) {
      setValue('maximalLoad23', '');
      setValue('digitStep23', '');
    } else {
      setValue('maximalLoad23', maximalLoad23?.toString());
      setValue('digitStep23', digitStep23?.toString());
    }
  }, [
    area13IsDisabled,
    area22IsDisabled,
    area23IsDisabled,
    setValue,
    maximalLoad13,
    digitStep13,
    maximalLoad23,
    digitStep23,
    maximalLoad22,
    digitStep22,
  ]);

  interface AreaTextFieldObject {
    defaultValue: string | undefined;
    inputChange: (value: string) => void;
    error: any;
  }
  const getMinimalArea = (area: number) => {
    let currentAreaTextField: AreaTextFieldObject;
    switch (area) {
      case 11:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.firstMinimalLoad ? bridge1.firstMinimalLoad.toString() : '',
          inputChange: minimalLoadArea11Changed,
          error: errors.minimalLoad11,
        };
        return currentAreaTextField;
      case 12:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.secondMinimalLoad ? bridge1.secondMinimalLoad.toString() : '',
          inputChange: minimalLoadArea12Changed,
          error: errors.minimalLoad12,
        };
        return currentAreaTextField;
      case 13:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.thirdMinimalLoad ? bridge1.thirdMinimalLoad.toString() : '',
          inputChange: minimalLoadArea13Changed,
          error: errors.minimalLoad13,
        };
        return currentAreaTextField;
      case 21:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.firstMinimalLoad ? bridge2.firstMinimalLoad.toString() : '',
          inputChange: minimalLoadArea21Changed,
          error: errors.minimalLoad21,
        };
        return currentAreaTextField;
      case 22:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.secondMinimalLoad ? bridge2.secondMinimalLoad.toString() : '',
          inputChange: minimalLoadArea22Changed,
          error: errors.minimalLoad22,
        };
        return currentAreaTextField;
      case 23:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.thirdMinimalLoad ? bridge2.thirdMinimalLoad.toString() : '',
          inputChange: minimalLoadArea23Changed,
          error: errors.minimalLoad23,
        };
        return currentAreaTextField;
    }
  };

  const getMaximalArea = (area: number) => {
    let currentAreaTextField: AreaTextFieldObject;
    switch (area) {
      case 11:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.firstMaximalLoad ? bridge1.firstMaximalLoad.toString() : '',
          inputChange: maximalLoadArea11Changed,
          error: errors.maximalLoad11,
        };
        return currentAreaTextField;
      case 12:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.secondMaximalLoad ? bridge1.secondMaximalLoad.toString() : '',
          inputChange: maximalLoadArea12Changed,
          error: errors.maximalLoad12,
        };
        return currentAreaTextField;
      case 13:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.thirdMaximalLoad ? bridge1.thirdMaximalLoad.toString() : '',
          inputChange: maximalLoadArea13Changed,
          error: errors.maximalLoad13,
        };
        return currentAreaTextField;
      case 21:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.firstMaximalLoad ? bridge2.firstMaximalLoad.toString() : '',
          inputChange: maximalLoadArea21Changed,
          error: errors.maximalLoad21,
        };
        return currentAreaTextField;
      case 22:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.secondMaximalLoad ? bridge2.secondMaximalLoad.toString() : '',
          inputChange: maximalLoadArea22Changed,
          error: errors.maximalLoad22,
        };
        return currentAreaTextField;
      case 23:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.thirdMaximalLoad ? bridge2.thirdMaximalLoad.toString() : '',
          inputChange: maximalLoadArea23Changed,
          error: errors.maximalLoad23,
        };
        return currentAreaTextField;
    }
  };

  const getDigitStepArea = (area: number) => {
    let currentAreaTextField: AreaTextFieldObject;
    switch (area) {
      case 11:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.firstDigitStep ? bridge1.firstDigitStep.toString() : '',
          inputChange: digitStepArea11Changed,
          error: errors.digitStep11,
        };
        return currentAreaTextField;
      case 12:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.secondDigitStep ? bridge1.secondDigitStep.toString() : '',
          inputChange: digitStepArea12Changed,
          error: errors.digitStep12,
        };
        return currentAreaTextField;
      case 13:
        currentAreaTextField = {
          defaultValue:
            !!bridge1 && bridge1.thirdDigitStep ? bridge1.thirdDigitStep.toString() : '',
          inputChange: digitStepArea13Changed,
          error: errors.digitStep13,
        };
        return currentAreaTextField;
      case 21:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.firstDigitStep ? bridge2.firstDigitStep.toString() : '',
          inputChange: digitStepArea21Changed,
          error: errors.digitStep21,
        };
        return currentAreaTextField;
      case 22:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.secondDigitStep ? bridge2.secondDigitStep.toString() : '',
          inputChange: digitStepArea22Changed,
          error: errors.digitStep22,
        };
        return currentAreaTextField;
      case 23:
        currentAreaTextField = {
          defaultValue:
            !!bridge2 && bridge2.thirdDigitStep ? bridge2.thirdDigitStep.toString() : '',
          inputChange: digitStepArea23Changed,
          error: errors.digitStep23,
        };
        return currentAreaTextField;
    }
  };

  const minimalLoad = (area: number, disabled?: boolean, value?: string) => {
    const currentArea = getMinimalArea(area);
    return (
      <GridItem s={6}>
        <FormattedTextFieldComponent
          key={'minimalLoad' + area}
          name={'minimalLoad' + area}
          onInputChange={currentArea!.inputChange}
          defaultValue={currentArea!.defaultValue || ''}
          value={value}
          label={t('data:device.minimalLoad')}
          ariaLabel={t('data:device.minimalLoad')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          disabled={disabled}
          required={area === 11}
          hasError={currentArea!.error !== undefined}
          errorMessage={currentArea!.error && currentArea!.error.message}
        />
      </GridItem>
    );
  };

  const maximalLoad = (area: number, disabled?: boolean) => {
    const currentArea = getMaximalArea(area);
    return (
      <GridItem s={6}>
        <FormattedTextFieldComponent
          key={'maximalLoad' + area}
          name={'maximalLoad' + area}
          onInputChange={currentArea!.inputChange}
          defaultValue={currentArea!.defaultValue || ''}
          label={t('data:device.maximalLoad')}
          ariaLabel={t('data:device.maximalLoad')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          disabled={disabled}
          required={area === 11}
          hasError={currentArea!.error !== undefined}
          errorMessage={currentArea!.error && currentArea!.error.message}
        />
      </GridItem>
    );
  };

  const digitStep = (area: number, disabled?: boolean) => {
    const currentArea = getDigitStepArea(area);
    return (
      <GridItem s={6}>
        <FormattedTextFieldComponent
          key={'digitStep' + area}
          name={'digitStep' + area}
          onInputChange={currentArea!.inputChange}
          defaultValue={currentArea!.defaultValue || ''}
          label={t('data:device.digitStep')}
          ariaLabel={t('data:device.digitStep')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          disabled={disabled}
          required={area === 11}
          hasError={currentArea!.error !== undefined}
          errorMessage={currentArea!.error && currentArea!.error.message}
        />
      </GridItem>
    );
  };

  return (
    <Box data-testid="weighingBridges">
      <Typography variant="h2">{t('data:device:weighingBridge', { count: 1 })}</Typography>
      <Paper>
        <ContainerInside>
          <GridContainer level={GridLevel.InputPaper}>
            <GridItem s={6} xl={4}>
              <Typography variant="h3">{`${t('data:device:area.1', { count: 1 })}*`}</Typography>
              <GridContainer level={GridLevel.InputPaper}>
                {minimalLoad(11)}
                {maximalLoad(11)}
                {digitStep(11)}
              </GridContainer>
            </GridItem>
            <GridItem s={6} xl={4}>
              <Typography variant="h3">{t('data:device:area.2', { count: 1 })}</Typography>
              <GridContainer level={GridLevel.InputPaper}>
                {minimalLoad(12, true, minimalLoad12Watched)}
                {maximalLoad(12, area12IsDisabled)}
                {digitStep(12, area12IsDisabled)}
              </GridContainer>
            </GridItem>
            <GridItem s={6} xl={4}>
              <Typography variant="h3">{t('data:device:area.3', { count: 1 })}</Typography>
              <GridContainer level={GridLevel.InputPaper}>
                {minimalLoad(13, true, minimalLoad13Watched)}
                {maximalLoad(13, area13IsDisabled)}
                {digitStep(13, area13IsDisabled)}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ContainerInside>
      </Paper>
      <Typography variant="h2">{t('data:device:weighingBridge', { count: 2 })}</Typography>
      <Paper>
        <ContainerInside>
          <GridContainer level={GridLevel.InputPaper}>
            <GridItem s={6} xl={4}>
              <Typography variant="h3">{t('data:device:area.1', { count: 2 })}</Typography>
              <GridContainer level={GridLevel.InputPaper}>
                {minimalLoad(21, area21IsDisabled)}
                {maximalLoad(21, area21IsDisabled)}
                {digitStep(21, area21IsDisabled)}
              </GridContainer>
            </GridItem>
            <GridItem s={6} xl={4}>
              <Typography variant="h3">{t('data:device:area.2', { count: 2 })}</Typography>
              <GridContainer level={GridLevel.InputPaper}>
                {minimalLoad(22, true, minimalLoad22Watched)}
                {maximalLoad(22, area22IsDisabled)}
                {digitStep(22, area22IsDisabled)}
              </GridContainer>
            </GridItem>
            <GridItem s={6} xl={4}>
              <Typography variant="h3">{t('data:device:area.3', { count: 2 })}</Typography>
              <GridContainer level={GridLevel.InputPaper}>
                {minimalLoad(23, true, minimalLoad23Watched)}
                {maximalLoad(23, area23IsDisabled)}
                {digitStep(23, area23IsDisabled)}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ContainerInside>
      </Paper>
    </Box>
  );
};
