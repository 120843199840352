import WarningIcon from '@mui/icons-material/Warning';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormattedNumberOutputComponent } from '../../../components/form/formatted-number-output/formatted-number-output.component';
import { WEIGHT_ENTITY, TEMPERATURE_ENTITY, VOLUME_ENTITY } from '../../../constants';
import {
  CheckAttributeType,
  ProductionRunCheckExecution,
  CheckResult,
  DisruptionCode,
  ProductionRunCheckExecutionSample,
  ResultStatus,
  ResultType,
} from '../../../model';
import { openProductionRunSkipReasonDialog, openProductionRunFreeTextDialog } from '../../../store';
import { irisCheckResultColors } from '../../../theme';

import {
  checkResultsCellContentClasses,
  CheckResultsCellContentRoot,
} from './check-results-cell-content.styles';

export interface OwnProps {
  execution: ProductionRunCheckExecution;
}

export const CheckResultCellContentComponent = (props: OwnProps) => {
  const { execution } = props;
  const { sampleSize } = execution.checkAttribute;
  const isFertigPackV =
    execution.checkAttribute.checkAttributeType === CheckAttributeType.FertigPackV;
  const isWeight = execution.checkAttribute.checkAttributeType === CheckAttributeType.Weight;
  const isBarcode = execution.checkAttribute.checkAttributeType === CheckAttributeType.Barcode;
  const isVolume = execution.checkAttribute.checkAttributeType === CheckAttributeType.Volume;

  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);

  const handleOpenSkipReasonDialog = (
    execution: ProductionRunCheckExecution,
    resultType: ResultType
  ) => {
    dispatch(openProductionRunSkipReasonDialog(execution, resultType));
  };

  const handleOpenFreeTextDialog = (result: ProductionRunCheckExecution) => {
    dispatch(openProductionRunFreeTextDialog(result));
  };

  const getResultSampleValue = (
    execution: ProductionRunCheckExecution,
    sample: ProductionRunCheckExecutionSample
  ) => {
    switch (execution.checkAttribute.checkAttributeType) {
      case CheckAttributeType.FertigPackV:
        if (sample.volumeDisplay) {
          return [sample.weightDisplay, sample.volumeDisplay];
        } else {
          return [sample.weightDisplay];
        }
      case CheckAttributeType.Weight:
        return [sample.weightDisplay];
      case CheckAttributeType.Temperature:
        return [sample.temperature];
      case CheckAttributeType.Volume:
        return [sample.weightDisplay, sample.volumeDisplay];
      case CheckAttributeType.Barcode:
        return [sample.barcode];
      default:
        return '';
    }
  };

  const getFertigPackVCategory = (sample: ProductionRunCheckExecutionSample) => {
    return (
      <Typography>
        &nbsp;
        {`(${t([
          `data:check.fertigPackVCategories.${sample.fertigPackVCategory}`,
          `data:check.fertigPackVCategories.notFoundShort`,
        ])})`}
      </Typography>
    );
  };

  const getSkipReasonLink = (
    execution: ProductionRunCheckExecution,
    resultType: ResultType,
    index: number = 0
  ) => {
    return (
      <Box className={checkResultsCellContentClasses.resultBox} key={`link${index}`}>
        <Box
          className={checkResultsCellContentClasses.dot}
          bgcolor={irisCheckResultColors.skipped}
        ></Box>
        <Link
          component="button"
          variant="body2"
          underline="none"
          onClick={() => handleOpenSkipReasonDialog(execution, resultType)}
        >
          {t('data:checkResult.showReason')}
        </Link>
      </Box>
    );
  };

  const getDisruptionMessage = (
    execution: ProductionRunCheckExecution,
    sample?: ProductionRunCheckExecutionSample,
    index: number = 0
  ) => {
    return (
      <Box className={checkResultsCellContentClasses.resultBox} key={`disruption${index}`}>
        <WarningIcon fontSize="large" className={checkResultsCellContentClasses.disruptionIcon} />
        {(sample && sample.cancelledBy === DisruptionCode.FertigPackVTU2Violation) ||
        execution.cancelledBy === DisruptionCode.FertigPackVTU2Violation
          ? t('data:productionRun.tu2.cancelled')
          : t('data:productionRun.cancelled')}
      </Box>
    );
  };

  if (
    !(isFertigPackV || isWeight || isVolume || isBarcode) &&
    execution.status === ResultStatus.Skipped
  ) {
    return (
      <CheckResultsCellContentRoot>
        {getSkipReasonLink(execution, ResultType.Check)}
      </CheckResultsCellContentRoot>
    );
  } else if (
    !(isFertigPackV || isWeight || isVolume || isBarcode) &&
    execution.status === ResultStatus.Cancelled
  ) {
    return (
      <CheckResultsCellContentRoot>{getDisruptionMessage(execution)}</CheckResultsCellContentRoot>
    );
  }

  switch (execution.checkAttribute.checkAttributeType) {
    case CheckAttributeType.YesNo:
    case CheckAttributeType.TrafficLight:
      return (
        <CheckResultsCellContentRoot>
          <Box className={checkResultsCellContentClasses.outerBox}>
            {execution.passed > 0 && (
              <Box display="flex" alignItems="center">
                <Box
                  className={checkResultsCellContentClasses.dot}
                  bgcolor={irisCheckResultColors.passed}
                ></Box>
                {sampleSize > 1 && <span>{execution.passed}</span>}
              </Box>
            )}
            {execution.sufficient > 0 && (
              <Box display="flex" alignItems="center">
                <Box
                  className={checkResultsCellContentClasses.dot}
                  bgcolor={irisCheckResultColors.sufficient}
                ></Box>
                {sampleSize > 1 && <span>{execution.sufficient}</span>}
              </Box>
            )}
            {execution.failed > 0 && (
              <Box display="flex" alignItems="center">
                <Box
                  className={checkResultsCellContentClasses.dot}
                  bgcolor={irisCheckResultColors.failed}
                ></Box>
                {sampleSize > 1 && <span>{execution.failed}</span>}
              </Box>
            )}
          </Box>
        </CheckResultsCellContentRoot>
      );
    case CheckAttributeType.Weight:
    case CheckAttributeType.FertigPackV:
    case CheckAttributeType.Temperature:
    case CheckAttributeType.Volume:
      return (
        <CheckResultsCellContentRoot>
          <Box className={checkResultsCellContentClasses.outerBox}>
            {execution.samples &&
              execution.samples.map((sample, index) => {
                return (isFertigPackV || isWeight || isVolume) &&
                  sample.checkResult === CheckResult.Skipped ? (
                  getSkipReasonLink(execution, ResultType.Sample, index)
                ) : (isFertigPackV || isWeight || isVolume) &&
                  sample.checkResult === CheckResult.Cancelled ? (
                  getDisruptionMessage(execution, sample, index)
                ) : (
                  <Box className={checkResultsCellContentClasses.resultBox} key={index}>
                    <Box
                      className={checkResultsCellContentClasses.dot}
                      bgcolor={
                        sample.passed
                          ? irisCheckResultColors.passed
                          : sample.failed
                          ? irisCheckResultColors.failed
                          : irisCheckResultColors.sufficient
                      }
                    ></Box>
                    {execution.samples!.length > 1 && (
                      <span className={checkResultsCellContentClasses.sampleText}>{`${t(
                        'data:checkAttribute.sample'
                      )} ${index + 1}:`}</span>
                    )}
                    {sample.volumeDisplay &&
                      (execution.checkAttribute.checkAttributeType ===
                        CheckAttributeType.FertigPackV ||
                        execution.checkAttribute.checkAttributeType ===
                          CheckAttributeType.Volume) && (
                        <>
                          <FormattedNumberOutputComponent
                            value={getResultSampleValue(execution, sample)[1]}
                          />
                          <Typography className={checkResultsCellContentClasses.entitySuffix}>
                            {VOLUME_ENTITY}
                          </Typography>
                          <span className={checkResultsCellContentClasses.seperator}>{'/'}</span>
                        </>
                      )}
                    <FormattedNumberOutputComponent
                      value={getResultSampleValue(execution, sample)[0]}
                    />
                    <Typography className={checkResultsCellContentClasses.entitySuffix}>
                      {execution.checkAttribute.checkAttributeType ===
                      CheckAttributeType.Temperature
                        ? TEMPERATURE_ENTITY
                        : WEIGHT_ENTITY}
                    </Typography>
                    {isFertigPackV && getFertigPackVCategory(sample)}
                  </Box>
                );
              })}
          </Box>
        </CheckResultsCellContentRoot>
      );
    case CheckAttributeType.FreeText:
      return (
        <CheckResultsCellContentRoot>
          <Box className={checkResultsCellContentClasses.outerBox}>
            {execution.samples &&
              execution.samples.map((sample, index) => {
                return (
                  <Box className={checkResultsCellContentClasses.resultBox} key={index}>
                    <Box
                      className={checkResultsCellContentClasses.dot}
                      bgcolor={irisCheckResultColors.unrated}
                    ></Box>
                    {execution.samples!.length > 1 && (
                      <span className={checkResultsCellContentClasses.sampleText}>{`${t(
                        'data:checkAttribute.sample'
                      )} ${index + 1}:`}</span>
                    )}
                    <Typography className={checkResultsCellContentClasses.limitText}>
                      {sample.freeText}
                    </Typography>
                  </Box>
                );
              })}
            <Box className={checkResultsCellContentClasses.center}>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() => handleOpenFreeTextDialog(execution)}
              >
                {t('data:checkResult.showFreeText')}
              </Link>
            </Box>
          </Box>
        </CheckResultsCellContentRoot>
      );
    case CheckAttributeType.Barcode:
      return (
        <CheckResultsCellContentRoot>
          <Box className={checkResultsCellContentClasses.outerBox}>
            {execution.samples &&
              execution.samples.map((sample, index) => {
                return sample.checkResult === CheckResult.Skipped ? (
                  getSkipReasonLink(execution, ResultType.Sample, index)
                ) : sample.checkResult === CheckResult.Cancelled ? (
                  getDisruptionMessage(execution, sample, index)
                ) : (
                  <Box className={checkResultsCellContentClasses.resultBox} key={index}>
                    <Box
                      className={checkResultsCellContentClasses.dot}
                      bgcolor={
                        sample.passed ? irisCheckResultColors.passed : irisCheckResultColors.failed
                      }
                    ></Box>
                    {execution.samples!.length > 1 && (
                      <span className={checkResultsCellContentClasses.sampleText}>{`${t(
                        'data:checkAttribute.sample'
                      )} ${index + 1}:`}</span>
                    )}
                    <Typography className={checkResultsCellContentClasses.limitText}>
                      {sample.barcode}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </CheckResultsCellContentRoot>
      );
    default:
      return <></>;
  }
};
