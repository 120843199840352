import { Paper, Box, Typography, styled } from '@mui/material';
import { t } from 'i18next';

import { FormattedNumberOutputComponent } from '../../../components/form/formatted-number-output/formatted-number-output.component';
import { ContainerInside } from '../../../components/structure';
import { WEIGHT_ENTITY } from '../../../constants';
import { getFertigPackVStatistics, getSuffix } from '../../../helper';
import { ProductionRun } from '../../../model';
import { getPixels, irisSpacing, irisSpecialColors, responsiveness } from '../../../theme';

const SymbolTextBox = styled(Box)(({ theme }) => ({
  color: irisSpecialColors.attributeCaption,
  fontSize: '32px',
  fontWeight: 'bold',
}));

const DividerTextBox = styled(SymbolTextBox)(({ theme }) => ({
  marginLeft: theme.spacing(responsiveness.grid.text.space.x),
  marginRight: theme.spacing(responsiveness.grid.text.space.x),
}));

const ValueTextBox = styled(Box)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '36px',
  marginLeft: theme.spacing(responsiveness.grid.text.space.x),
}));

const StatisticsBox = styled(Box)(({ theme }) => ({
  height: 200 - getPixels(irisSpacing.container.p) * 2,
  display: 'flex',
  flexDirection: 'column',
}));

const StatisticalMeasuresBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(responsiveness.grid.text.space.y),
  marginBottom: theme.spacing(responsiveness.grid.text.space.y),
}));

interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunFertigPackVStatisticsComponent = (props: OwnProps) => {
  const { productionRun } = props;

  const statistics = getFertigPackVStatistics(productionRun);

  const suffix = getSuffix(!!productionRun?.article.volumeValue);
  const averageValue =
    statistics?.checkSamplesAverageVolume ?? statistics?.checkSamplesAverageWeight;
  const standardDeviation =
    statistics?.checkSamplesStandardVolumeDeviation ?? statistics?.checkSamplesStandardDeviation;
  const hasDeviation = !!standardDeviation;

  return averageValue ? (
    <Paper data-testid="ProductionRunFertigPackVStatisticsComponent">
      <ContainerInside>
        <StatisticsBox>
          <Typography variant="h3">{t('data:article.FertigPackV')}</Typography>
          <StatisticalMeasuresBox>
            <SymbolTextBox>{'x̄'}</SymbolTextBox>
            <ValueTextBox data-testid={'averageValue'}>
              <FormattedNumberOutputComponent value={averageValue} />
              {` ${suffix}`}
            </ValueTextBox>
            {hasDeviation && (
              <>
                <DividerTextBox>{'|'}</DividerTextBox>
                <SymbolTextBox>{'s'}</SymbolTextBox>
                <ValueTextBox data-testid={'StandardDeviationValue'}>
                  <FormattedNumberOutputComponent value={standardDeviation} decimalScale={2} />
                  {` ${suffix}`}
                </ValueTextBox>
              </>
            )}
          </StatisticalMeasuresBox>
          <Box mt={1} data-testid={'nominalValueTareValue'}>
            {`${t('data:article.nominalValue')}: ${
              productionRun.article.volumeValue ?? productionRun.article.nominalValue ?? '-'
            } ${suffix} | ${t('data:checkAttribute.tareValue')}: ${
              productionRun.article.tareValue ?? '-'
            } ${WEIGHT_ENTITY}`}
          </Box>
        </StatisticsBox>
      </ContainerInside>
    </Paper>
  ) : (
    <></>
  );
};
