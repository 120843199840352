import { VOLUME_ENTITY, WEIGHT_ENTITY } from '../../constants';
import { IssueCode, ProductionRun, ProductionRunStatus } from '../../model';
import { hasIssue } from '../issue/issue.helper';

export const hasActiveTestRunAfterStart = (productionRun: ProductionRun) => {
  return productionRun.status === ProductionRunStatus.InTestRunAfterStart;
};

export const hasActiveTestRunAfterDisruption = (productionRun: ProductionRun) => {
  return productionRun.status === ProductionRunStatus.InTestRunAfterDisruption;
};

export const hasActiveTestRun = (productionRun: ProductionRun) => {
  return (
    hasActiveTestRunAfterStart(productionRun) || hasActiveTestRunAfterDisruption(productionRun)
  );
};

export const getFertigPackVStatistics = (productionRun: ProductionRun) => {
  return productionRun.fertigPackVData.statistics;
};

export const isRunPausedManually = (productionRun: ProductionRun) => {
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const hasCriticalTU1Violation = productionRun.fertigPackVData.hasCriticalTU1Violation;
  const hasCriticalAverageWeightViolation =
    productionRun.fertigPackVData.hasCriticalAverageWeightBelowNominalViolation;

  return (
    productionRun.status === ProductionRunStatus.Paused &&
    !hasTU2Violation &&
    !hasCriticalTU1Violation &&
    !hasCriticalAverageWeightViolation
  );
};

export const hasMessageFlagIssues = (productionRun: ProductionRun) => {
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const hasTU1Violation = hasIssue(productionRun, IssueCode.FertigPackVTU1Violation);
  const hasCriticalTU1Violation = productionRun.fertigPackVData.hasCriticalTU1Violation;
  const hasAverageWeightViolation = hasIssue(productionRun, IssueCode.AverageWeightBelowNominal);
  const hasCriticalAverageWeightViolation =
    productionRun.fertigPackVData.hasCriticalAverageWeightBelowNominalViolation;
  const hasAnyActiveTestRun = hasActiveTestRun(productionRun);
  const isPaused = isRunPausedManually(productionRun);

  return (
    isPaused ||
    hasTU2Violation ||
    hasTU1Violation ||
    hasCriticalTU1Violation ||
    hasAverageWeightViolation ||
    hasAnyActiveTestRun ||
    hasCriticalAverageWeightViolation ||
    productionRun.disrupted === true
  );
};

export const getSuffix = (hasVolumeValue: boolean) => {
  return hasVolumeValue ? VOLUME_ENTITY : WEIGHT_ENTITY;
};
