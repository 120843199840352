import { Box, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { FormattedTextFieldComponent } from '../../../../components/formatted-text-field/formatted-text-field.component';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { InfoboxComponent } from '../../../../components/infobox/infobox.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { WEIGHT_ENTITY } from '../../../../constants';
import { CheckAttribute, CheckAttributeSpecification, CheckAttributeType } from '../../../../model';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

const PREFIX = 'WeightFormComponent';
const classes = {
  inputItemExtraShort: `${PREFIX}-inputItemExtraShort`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.inputItemExtraShort}`]: {
    width: 'inherit',
    maxWidth: 150,
  },
}));

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  weightDescription: string;
  weightNominalValue: number | string;
  weightMinValue: number | string;
  weightMaxValue: number | string;
  weightTareValue: number | string;
  limitValues: string;
  sampleSize: number;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  weightDescription: checkAttribute?.weightDescription || '',
  weightNominalValue:
    checkAttribute?.weightNominalValue !== undefined ? checkAttribute.weightNominalValue : '',
  weightMinValue: checkAttribute?.weightMinValue !== undefined ? checkAttribute.weightMinValue : '',
  weightMaxValue: checkAttribute?.weightMaxValue !== undefined ? checkAttribute.weightMaxValue : '',
  weightTareValue:
    checkAttribute?.weightTareValue !== undefined ? checkAttribute.weightTareValue : '',
  limitValues: '',
  sampleSize: checkAttribute?.sampleSize || 1,
});

export const WeightFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;
  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = methods;
  const [weightNominalValueInput, setWeightNominalValueInput] = useState<string | undefined>(
    checkAttribute?.weightNominalValue?.toString() || ''
  );

  const [weightMinValueInput, setWeightMinValueInput] = useState<string | undefined>(
    checkAttribute?.weightMinValue?.toString() || ''
  );

  const [weightMaxValueInput, setWeightMaxValueInput] = useState<string | undefined>(
    checkAttribute?.weightMaxValue?.toString() || ''
  );

  const [weightTareValueInput, setWeightTareValueInput] = useState<string | undefined>(
    checkAttribute?.weightTareValue?.toString() || ''
  );

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      checkAttributeType: CheckAttributeType.Weight,
      name: formData.checkAttributeName,
      specification: CheckAttributeSpecification.Weight,
      sampleSize: Number(formData.sampleSize),
      weightDescription: formData.weightDescription,
      weightNominalValue:
        weightNominalValueInput && weightNominalValueInput !== ''
          ? +weightNominalValueInput
          : undefined,
      weightMinValue:
        weightMinValueInput && weightMinValueInput !== '' ? +weightMinValueInput : undefined,
      weightMaxValue:
        weightMaxValueInput && weightMaxValueInput !== '' ? +weightMaxValueInput : undefined,
      weightTareValue:
        weightTareValueInput && weightTareValueInput !== '' ? +weightTareValueInput : undefined,
    });
  });

  const weightNominalValueInputChanged = (value: string) => {
    if (weightNominalValueInput !== value) {
      setWeightNominalValueInput(value);
      setValue('weightNominalValue', value);
    }
  };

  const weightMinValueInputChanged = (value: string) => {
    if (weightMinValueInput !== value) {
      setWeightMinValueInput(value);
      setValue('weightMinValue', value);
    }
  };

  const weightMaxValueInputChanged = (value: string) => {
    if (weightMaxValueInput !== value) {
      setWeightMaxValueInput(value);
      setValue('weightMaxValue', value);
    }
  };

  const weightTareValueInputChanged = (value: string) => {
    if (weightTareValueInput !== value) {
      setWeightTareValueInput(value);
      setValue('weightTareValue', value);
    }
  };

  const validateLimitValues = (): boolean => {
    const formData = getValues();

    const hasMinValue = formData.weightMinValue && formData.weightMinValue !== '';
    const hasNominalValue = formData.weightNominalValue && formData.weightNominalValue !== '';
    const hasMaxValue = formData.weightMaxValue && formData.weightMaxValue !== '';

    if (!(hasMinValue && hasNominalValue && hasMaxValue)) return true;

    const minValue = Number(formData.weightMinValue);
    const nominalValue = Number(formData.weightNominalValue);
    const maxValue = Number(formData.weightMaxValue);

    const isValid = minValue < nominalValue && nominalValue < maxValue;

    const returnValue = !!isValid || false;
    return returnValue;
  };

  return (
    <Root>
      <FormRoot>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className={formClasses.root}>
            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
              <Paper>
                <ContainerInside>
                  <GridContainer>
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <DescriptionFrameComponent checkAttributeType={CheckAttributeType.Weight} />
                        <GridItem s={12} xl={8}>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth={true}
                                label={t('data:checkAttribute.weightDescription')}
                                inputProps={{
                                  'aria-label': t('data:checkAttribute.weightDescription'),
                                  'data-testid': 'weightDescription-input',
                                }}
                                error={errors.weightDescription !== undefined}
                                helperText={
                                  errors.weightDescription && errors.weightDescription.message
                                }
                              />
                            )}
                            control={control}
                            defaultValue={''}
                            name="weightDescription"
                            rules={{
                              maxLength: {
                                value: 256,
                                message: t('form:maxLength', { max: '256' }),
                              },
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutsideWithHeader>
            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('data:checkAttribute.limits')}</Typography>
              <Paper>
                <ContainerInside>
                  <GridContainer level={GridLevel.InputPaper}>
                    <Controller
                      name="limitValues"
                      control={control}
                      defaultValue={' '}
                      render={({ field }) => <input {...field} type="hidden" />}
                      rules={{
                        validate: () =>
                          validateLimitValues() ||
                          (t('data:checkAttribute.limitValuesNotValid') as string),
                      }}
                    />
                    {errors.limitValues && errors.limitValues.message && (
                      <GridItem>
                        <InfoboxComponent
                          headline={errors.limitValues.message}
                          type="error"
                        ></InfoboxComponent>
                      </GridItem>
                    )}
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <Controller
                              name="weightNominalValue"
                              control={control}
                              render={() => (
                                <FormattedTextFieldComponent
                                  name="weightNominalValue"
                                  onInputChange={weightNominalValueInputChanged}
                                  defaultValue={
                                    checkAttribute?.weightNominalValue?.toString() || ''
                                  }
                                  label={t('data:checkAttribute.nominalValue')}
                                  ariaLabel={t('data:checkAttribute.nominalValue')}
                                  required
                                  fullWidth={true}
                                  suffixUnit={WEIGHT_ENTITY}
                                  hasError={errors.weightNominalValue !== undefined}
                                  errorMessage={
                                    errors.weightNominalValue && errors.weightNominalValue.message
                                  }
                                />
                              )}
                              rules={{
                                required: { value: true, message: t('form:fieldIsRequired') },
                                validate: (value: string | number) => {
                                  return !Number.isNaN(Number(value))
                                    ? true
                                    : (t('form:numberValue') as string);
                                },
                                min: { value: 0.001, message: t('form:minValue', { min: 0.001 }) },
                                max: {
                                  value: 1500000.0,
                                  message: t('form:maxValue', { max: 1500000.0 }),
                                },
                              }}
                            />
                          </Box>
                        </GridItem>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <Controller
                              name="weightTareValue"
                              control={control}
                              render={() => (
                                <FormattedTextFieldComponent
                                  name="weightTareValue"
                                  onInputChange={weightTareValueInputChanged}
                                  defaultValue={checkAttribute?.weightTareValue?.toString() || ''}
                                  label={t('data:checkAttribute.tareValue')}
                                  ariaLabel={t('data:checkAttribute.tareValue')}
                                  fullWidth={true}
                                  suffixUnit={WEIGHT_ENTITY}
                                  hasError={errors.weightTareValue !== undefined}
                                  errorMessage={
                                    errors.weightTareValue && errors.weightTareValue.message
                                  }
                                />
                              )}
                              rules={{
                                validate: (value: string | number) => {
                                  return !Number.isNaN(Number(value))
                                    ? true
                                    : (t('form:numberValue') as string);
                                },
                                min: { value: 0.001, message: t('form:minValue', { min: 0.001 }) },
                                max: {
                                  value: 1500000.0,
                                  message: t('form:maxValue', { max: 1500000.0 }),
                                },
                              }}
                            />
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <Controller
                              name="weightMinValue"
                              control={control}
                              render={() => (
                                <FormattedTextFieldComponent
                                  name="weightMinValue"
                                  onInputChange={weightMinValueInputChanged}
                                  defaultValue={checkAttribute?.weightMinValue?.toString() || ''}
                                  label={t('data:checkAttribute.minValue')}
                                  ariaLabel={t('data:checkAttribute.minValue')}
                                  required
                                  fullWidth={true}
                                  suffixUnit={WEIGHT_ENTITY}
                                  hasError={errors.weightMinValue !== undefined}
                                  errorMessage={
                                    errors.weightMinValue && errors.weightMinValue.message
                                  }
                                />
                              )}
                              rules={{
                                required: { value: true, message: t('form:fieldIsRequired') },
                                validate: (value: string | number) => {
                                  return !Number.isNaN(Number(value))
                                    ? true
                                    : (t('form:numberValue') as string);
                                },
                                min: { value: 0.001, message: t('form:minValue', { min: 0.001 }) },
                                max: {
                                  value: 1500000.0,
                                  message: t('form:maxValue', { max: 1500000.0 }),
                                },
                              }}
                            />
                          </Box>
                        </GridItem>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <Controller
                              name="weightMaxValue"
                              control={control}
                              render={() => (
                                <FormattedTextFieldComponent
                                  name="weightMaxValue"
                                  onInputChange={weightMaxValueInputChanged}
                                  defaultValue={checkAttribute?.weightMaxValue?.toString() || ''}
                                  label={t('data:checkAttribute.maxValue')}
                                  ariaLabel={t('data:checkAttribute.maxValue')}
                                  required
                                  fullWidth={true}
                                  suffixUnit={WEIGHT_ENTITY}
                                  hasError={errors.weightMaxValue !== undefined}
                                  errorMessage={
                                    errors.weightMaxValue && errors.weightMaxValue.message
                                  }
                                />
                              )}
                              rules={{
                                required: { value: true, message: t('form:fieldIsRequired') },
                                validate: (value: string | number) => {
                                  return !Number.isNaN(Number(value))
                                    ? true
                                    : (t('form:numberValue') as string);
                                },
                                min: { value: 0.001, message: t('form:minValue', { min: 0.001 }) },
                                max: {
                                  value: 1500000.0,
                                  message: t('form:maxValue', { max: 1500000.0 }),
                                },
                              }}
                            />
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutsideWithHeader>
            <SampleSizeComponent />
            <GridItem>
              <ButtonsFormComponent cancel={props.cancel} />
            </GridItem>
          </form>
        </FormProvider>
      </FormRoot>
    </Root>
  );
};
