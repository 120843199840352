import { styled } from '@mui/material/styles';

import { irisCustomColors, irisSpacing } from '../../../../theme';

const PREFIX = 'StyledArticleForm';
export const articleFormClasses = {
  checkPeriodicityItemContainer: `${PREFIX}-checkPeriodicityItemContainer`,
  checkPeriodicityItem: `${PREFIX}-checkPeriodicityItem`,
  validationError: `${PREFIX}-validationError`,
  equalHeightContainer: `${PREFIX}-equalHeightContainer`,
  hidden: `${PREFIX}-hidden`,
  radioContent: `${PREFIX}-radioContent`,
  radioContentLastRow: `${PREFIX}-radioContentLastRow`,
  radioGroup: `${PREFIX}-radioGroup`,
};

export const ArticleFormRoot = styled('div')(({ theme }) => ({
  [`& .${articleFormClasses.checkPeriodicityItemContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${articleFormClasses.checkPeriodicityItem}`]: {
    width: 100,
  },
  [`& .${articleFormClasses.validationError}`]: {
    color: irisCustomColors.irisRed,
    fontSize: theme.spacing(1.5),
    marginTop: '3px',
  },
  [`& .${articleFormClasses.equalHeightContainer}`]: {
    height: '100%',
  },
  [`& .${articleFormClasses.hidden}`]: {
    display: 'none',
  },
  [`& .${articleFormClasses.radioContent}`]: {
    paddingTop: theme.spacing(irisSpacing.input.space),
    paddingLeft: theme.spacing(irisSpacing.input.space),
    paddingBottom: theme.spacing(irisSpacing.input.space),
  },
  [`& .${articleFormClasses.radioContentLastRow}`]: {
    paddingTop: theme.spacing(irisSpacing.input.space),
    paddingLeft: theme.spacing(irisSpacing.input.space),
  },
  [`& .${articleFormClasses.radioGroup}`]: {
    marginBottom: theme.spacing(irisSpacing.input.space),
  },
}));
