export const MAX_SRV_INT = 2147483647;
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const MAX_IMG_SIZE = 5242880; // 5MB
export const IMAGE_HEIGHT_SMALL = 75;
export const IMAGE_WIDTH_SMALL = 100;
export const IMAGE_HEIGHT_BIG = 150;
export const IMAGE_WIDTH_BIG = 200;
export const IMAGE_RATIO = 4 / 3;
export const WEIGHT_ENTITY = 'g';
export const VOLUME_ENTITY = 'ml';
export const DENSITY_ENTITY = 'g/ml';
export const TEMPERATURE_ENTITY = '°C';
export const SRA_PREFIX = 'SRA';
export const SERVICE_MAIL = 'service@hoefelmeyer.de';

export const MILLIS_SECOND = 1000;
export const MILLIS_MINUTE = MILLIS_SECOND * 60;
export const MILLIS_HOUR = MILLIS_MINUTE * 60;
export const MILLIS_DAY = MILLIS_HOUR * 24;
